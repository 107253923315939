<template>
    <SeeMoreInner>
        Hi
    </SeeMoreInner>
</template>

<script>
import SeeMoreInner from "@/components/SeeMoreInner.vue";
import pageTrans from "@/mixins/pagetrans";

export default {
  mixins: [pageTrans],
  components: {
    SeeMoreInner,
  },
  methods: {},
};
</script>